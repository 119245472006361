.cell {
  position: relative;
  width: 100%;
  height: 100%;
}

.cell-cupom {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 15px;
  text-align: left;
}

.container-home {
  height: 100%;
}

.header-home {
  display: flex;
  height: 30%;
}

.body-home {
  height: 70%;
  padding-bottom: 25px;
}

.label-home-msg {
  font-family: Roboto;
  font-style: normal;
  font-weight: 200;
  font-size: 35px;
  line-height: 60px;
  text-align: center;
  color: #ffffff;
}

.label-home-desconto {
  font-family: Roboto;
  font-weight: 500;
  font-size: 50px;
  line-height: 50px;
  text-align: center;
  color: #ffffff;
}

.label-home-desconto-inf {
  font-family: Roboto;
  text-align: center;
  color: #ffffff;
}

.cell-label-container {
  margin-bottom: 10px;
  padding: 0px 10px;
}

.cell-label-container label:first-child {
  margin-bottom: 0;
}

.cell-label-info {
  height: 10px;
  position: absolute;
  left: 10%;
  right: 10%;
  top: 5%;
  bottom: 50%;
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
}

.cell-label-email {
  position: absolute;
  left: 10%;
  top: 20%;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
}

.form-div {
  margin-top: 30%;
  background-color: #1a1a1a;
}

.form-label-info {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  color: #ffffff;
  width: 100%;
  text-align: left;
  padding-left: 10%;
}

.cell-label-info-footer {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #ffffff;
  padding-left: 10%;
  width: 100%;
  text-align: left;
  margin-bottom: 10%;
}

.form-label-input {
  background: #ffffff !important;
  border: 1px solid #e9e9e9 !important;
  width: 80% !important;
  box-sizing: border-box !important;
  border-radius: 0 !important;
  margin-left: 10%;
}

.btn-acessar-cupom {
  background: #737373 !important;
  border-radius: 4px !important;
  width: 80%;
  height: 10%;
  margin-bottom: 10px;
  font-family: Roboto !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 16px !important;
  line-height: 24px !important;
  background-color: #737373 !important;
  border-color: #737373 !important;
}

.image-logo {
  position: absolute;
  left: 40.28%;
  right: 40%;
  top: 7.81%;
  bottom: 90.31%;
}

.cupom-div {
  margin-bottom: 15px;
  width: 233px;
  height: 58px;
  border: 2px solid #ffffff;
  border-radius: 10px;
}

.cell-label-info-cupom {
  width: 100%;
  margin-top: 20px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
}

.cell-label-info-cupom-validade {
  width: 100%;
  margin-bottom: 15px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: #ffffff;
}

.cell-label-info1-cupom {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #ffffff;
}

.cell-label-info-cupom-obs {
  text-align: justify;
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 14px;
  color: #ffffff;
}

.cell-label-info-cupom-nome {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  line-height: 32px;
  text-align: center;
  color: #ffffff;
}

.form-cpf {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.MuiInputBase-root {
  margin-top: 0px !important;
  width: 295px;
}

.MuiSvgIcon-root {
  color: white;
}

.MuiTypography-root {
  color: white;
}

.MuiTypography-body1 {
  font-size: 13px !important;
}
