.container {
  padding: 0;
  width: 100vw;
  height: 100vh;
  background-color: #d12c1e;
}

.info {
  display: flex;
  justify-content: center;
}

.info h1 {
  margin-top: 40px;
  text-align: center;
  color: #fff;
}
