.App {
  text-align: center;
  width: 100%;
}

#root {
  width: 100%;
  height: 100%;
}

.container-div-up {
  height: 35%;
  background-color: #d12c1e;
  animation: go-back 1s;
}

@keyframes go-back {
  from {
    transform: translatey(-300px);
  }
  to {
    /* transform: translatey(50%); */
  }
}

.container-div-down {
  height: 100%;
  background-color: #1a1a1a;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: go-back1 1s;
}

@keyframes go-back1 {
  from {
    transform: translatey(500px);
  }
  to {
    /* transform: translatey(50%); */
  }
}
